import React from "react"
import NewButton from "../NewButton"

import "./style.scss"
import WebpImage from "../WebpImage"

const ExamHowItGoes = () => {
  return (
    <div className="how-it-goes">
      <div className="grid-section">
        <div className="text-block">
          <div className="title-how-it-goes">
            <h3 className="title text h3 green">
              You know how it goes with eye tests...
            </h3>

          </div>

          <div className="description-how-it-goes">
            <p className="description text h6 green">
              You go to order more contacts, only to find you need to schedule an in-office vision
              test to renew your contact lens prescription online (that’s an every year thing FYI).
              Hubble simplifies this process by offering our customers free online vision tests for
              contact lens prescription renewals. No need to go to the doctor to renew your prescription. Get it online, from home, for free, in 10 minutes or less. It’s that easy!
            </p>
          </div>
          <div className="button-container">
            <NewButton label="Start Your Subscription" className="mobile" to={"/contact-lenses/hubble"} />
          </div>
        </div>

        <div className="image-block">
          <WebpImage fileName="Pages/exam/Hubble-Free-Online-Eye-Exams.jpg" alt="Colorful boxes of Hubble Contacts" />
        </div>
      </div>
    </div>
  )
}

export default ExamHowItGoes
